import { useSchema } from '../../../../schema/SchemaReducer';
import { useSchemaDispatch } from '../../../../schema/SchemaReducer';
import { MERGE_PATCH } from '../../../../schema/JSONSchemaReducer';
import { pluck } from '../../../../fp/object';
import { DropDownList, Flexbox } from '../../../../components';
import { NameLabel } from '../../components/NameLabel';
import primarySchemaKeys from '../../primarySchemaKeys';
import { toAttrDetail, toAttributesObject } from '../../utils';
import { baseNodeValidator } from '../../validators';
import withClassNameModifiers from '../../../../utils/withClassNameModifiers';
import { useXeRefData } from '../../../../contexts/XeRefDataContext';
import { useSmartBookNodeContext } from '../../hooks';
import { toNodePropertiesWithDefaults } from '../utils';
import { fromStrTrueFalseToBoolTrueFalse } from '../../../../utils';

const DefaultComponent = (props) => {
  const { children, ...rest } = props;
  return (
    <Flexbox alignItems="center" {...rest}>
      {children}
    </Flexbox>
  );
};

const defaultValueFn = (value) => {
  const { text: ResultValue, id: ResultValueID } = value;

  return {
    ResultValue,
    ResultValueID: `${ResultValueID}`,
  };
};

const ComboWithRefData = (props) => {
  const {
    component: WrappingComponent = DefaultComponent,
    readOnly,
    node,
    onValidated,
    dataPath = primarySchemaKeys.Combo,
    refDataId: propsRefDataId,
    valueFn = defaultValueFn,
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const { AttrDetail, Name, IsRequired, IsVisible } = nodeWithDefaults;

  const { required: refDataId = propsRefDataId, Active } =
    toAttributesObject(AttrDetail);
  const booleanActive = fromStrTrueFalseToBoolTrueFalse(Active);

  const { [refDataId]: RefData } = useXeRefData();

  const { value, validityMessage, fullPath } = useSchema(dataPath);

  const { valid, onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  const schemaDispatch = useSchemaDispatch();

  if (!IsVisible) return null;
  const invalid = !valid || !!validityMessage;
  return (
    <WrappingComponent>
      <DropDownList
        dataElementName={Name}
        descriptor={Name}
        descriptorClassName={`${withClassNameModifiers('smartbook-descriptor', {
          invalid,
        })} margin-right-small`}
        required={IsRequired}
        disabled={readOnly}
        wrapperClass="margin-left-small"
        data={RefData}
        value={value}
        comparator={({ id } = {}, value) => {
          return value == id;
        }}
        labelFn={pluck('text')}
        onChange={(value) => {
          const nextValue = valueFn(value);

          schemaDispatch({
            type: MERGE_PATCH,
            value: nextValue,
          });
          onValidChange(
            baseNodeValidator(
              true,
              nextValue[primarySchemaKeys.Combo],
              IsRequired
            )
          );
        }}
        useActiveFilter={booleanActive}
      />
    </WrappingComponent>
  );
};

const ComboWithXeSmartBookInstanceData = (props) => {
  const {
    component: WrappingComponent = DefaultComponent,
    readOnly,
    node,
    onValidated,
    dataPath,
  } = props;

  const nodeWithDefaults = toNodePropertiesWithDefaults(node, props);
  const { Name, IsRequired, IsVisible, XeSmartBookInstance } = node;

  const { value, validityMessage, fullPath } = useSchema(
    primarySchemaKeys.Combo
  );

  const { valid, onValidChange } = useSmartBookNodeContext(
    nodeWithDefaults,
    dataPath,
    fullPath,
    onValidated
  );

  const schemaDispatch = useSchemaDispatch();

  if (!IsVisible) return null;

  return (
    <WrappingComponent>
      <NameLabel
        dataElementName={`${Name}__label`}
        required={IsRequired}
        invalid={!valid || !!validityMessage}
        className="margin-right-small"
      >
        {Name}
      </NameLabel>
      <DropDownList
        dataElementName={Name}
        required={IsRequired}
        disabled={readOnly}
        wrapperClass="margin-left-small"
        data={XeSmartBookInstance}
        labelFn={pluck('Name')}
        value={value}
        comparator={(item = {}, value) => {
          return item.ResultValueID === value;
        }}
        onChange={(value = {}) => {
          const { Name, ResultValueID } = value;
          const nextValue = {
            ResultValue: Name,
            ResultValueID: `${ResultValueID}`,
          };
          schemaDispatch({
            type: MERGE_PATCH,
            value: nextValue,
          });
          onValidChange(
            baseNodeValidator(
              true,
              nextValue[primarySchemaKeys.Combo],
              IsRequired
            )
          );
        }}
      />
    </WrappingComponent>
  );
};

export const Combo = (props) => {
  const { node } = props;
  const { required: refDataSource } = toAttributesObject(toAttrDetail(node));

  if (refDataSource === 'XeSmartBookInstance') {
    return <ComboWithXeSmartBookInstanceData {...props} />;
  }

  return <ComboWithRefData {...props} />;
};
