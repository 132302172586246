import document from '../../globals/document';
import {
  Window as KendoWindow,
  WindowActionsBar,
} from '@progress/kendo-react-dialogs';
import PropTypes from 'prop-types';
import { Children, useMemo } from 'react';
import { Label } from '../Label';
import { NULL_FUNCTION } from '../../constants';
import './styles.css';
import {
  toClassName,
  toPopupSizes,
  useViewportSize,
  getPopupBody,
  getFooterActionBar,
} from './utils';

const Header = ({ children }) => (
  <div className="xe-popup__header">{children}</div>
);

/**
 * @description xnetjs Popup component. Prefer importing and using the `Footer` component instead of
 * the `FooterComponent` prop or the `toDefaultPopupFooter` function.
 * @returns
 */
export const Window = (props) => {
  const {
    dataElementName,
    children,
    size,
    className,
    title,
    onClose,
    contentDisplayType,
    contentStyle,
    popupRootSelector,
    closeIcon,
  } = props;

  const popupRoot = document.querySelector(popupRootSelector);

  const elements = Children.toArray(children);

  // This pattern mimicks how kendo distinguishes between the popup body and footer (DialogActionsBar) elements.
  const PopupBody = getPopupBody(elements);
  const PopupFooterActionsBar = getFooterActionBar(elements);

  const viewportSize = useViewportSize();
  const { width, height, top, left } = useMemo(
    () => toPopupSizes(size, viewportSize),
    [size, viewportSize]
  );

  return (
    <KendoWindow
      ref={(ref) => {
        ref?.element?.setAttribute('data-element-name', dataElementName);
      }}
      closeButton={closeIcon ? closeIcon : NULL_FUNCTION}
      resizable={false}
      minimizeButton={NULL_FUNCTION}
      maximizeButton={NULL_FUNCTION}
      initialTop={top}
      initialLeft={left}
      modal={true}
      title={
        <Label
          dataElementName={title.replace(/\s/g, '')}
          className="xe-popup__title"
        >
          {title}
        </Label>
      }
      width={width}
      height={height}
      className={`${toClassName(size, className)} vertical-flex-container`}
      contentStyle={{ display: contentDisplayType, ...contentStyle }}
      onClose={onClose}
      appendTo={popupRoot}
    >
      {PopupBody}
      {PopupFooterActionsBar ? (
        <WindowActionsBar>{PopupFooterActionsBar}</WindowActionsBar>
      ) : null}
    </KendoWindow>
  );
};

Window.defaultProps = {
  dataElementName: '',
  title: '',
  size: 'medium',
  className: '',
  contentDisplayType: 'flex',
  contentStyle: {},
  onClose: () => {},
  popupRootSelector: '#appTarget #popup',
  closeIcon: false,
};

Window.propTypes = {
  dataElementName: PropTypes.string,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  size: PropTypes.oneOf([
    'auto',
    'small',
    'medium',
    'large',
    'x-large',
    'stretch',
  ]),
  className: PropTypes.string,
  contentDisplayType: PropTypes.oneOf(['block', 'grid', 'flex']),
  contentStyle: PropTypes.object,
  onClose: PropTypes.func,
  popupRootSelector: PropTypes.string,
  closeIcon: PropTypes.bool,
};
